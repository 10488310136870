import { KeycloakConfig, KeycloakInitOptions, KeycloakOptions } from 'keycloak-angular';

// Add here your keycloak configuration information
const keycloakConfig: KeycloakConfig = {
  url: 'https://iam.loh.essilor.com.br/auth/',
  realm: 'essilor',
  clientId: 'Lab On Hand'
};

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false
};

const keycloakOptions: KeycloakOptions = {
  config: keycloakConfig,
  initOptions: keycloakInitOptions,
  enableBearerInterceptor: true
};

export const environment = {
  production: false,
  mode: "Local",
  apiURL: 'https://api.loh.essilor.com.br',
  keycloakOptions
};

