import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { JobTypeEnum } from 'src/app/enums/job-type';
import { I18n } from 'src/app/i18n';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

const stack = 'stack';
const borderColor = 'rgb(255, 99, 132)';
const backgroundColorD5 = '#ff3300';
const backgroundColorD4 = '#ff5c00';
const backgroundColorD3 = '#ff7000';
const backgroundColorD2 = '#ff9900';
const backgroundColorD1 = '#ffc200';
const backgroundColorD0 = '#ffff00';
const backgroundColorD1n = '#cceb00';
const backgroundColorD2n = '#8cd100';
const backgroundColorD3n = '#66c200';

@Component({
  selector: 'app-late-orders-out-detail',
  templateUrl: './late-orders-out-detail.component.html',
  styleUrls: ['./late-orders-out-detail.component.scss']
})
export class LateOrdersOutDetailComponent implements OnInit {

  loading = false;

  constructor(
    private service: DataAnalyticsService,
    private t: I18n,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 20
      },
    },
    onHover: (event, chartElement) => {
      document.getElementById("canvasId").style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    maintainAspectRatio: false,
  };
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [];
  public barChartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public chartLevel: number = 1;
  public selectedSectorStatus: string = "";
  public selectedMngtStatus: string = "";
  public orderList: OrderDTO[] = [];
  public lengthLista = true;
  public loadingOrderList: boolean = true;
  public orderListPaginated: OrderDTO[] = [];
  public clickedLegendItems: boolean[] = [];
  public barChartDataQtdItems: number = 9;
  public selectedDays: string = "all";

  ngOnInit() {
    this.loading = true;
    this.initializeClickedLegendItems();
    this.getInitialData();
  }

  public getInitialData(){
    this.barChartLabels = [];
    this.initializeBarChartData();
    this.getBarChartData();
  }

  public initializeClickedLegendItems(){
    this.clickedLegendItems[0]=false;
    this.clickedLegendItems[1]=false;
    this.clickedLegendItems[2]=false;
    this.clickedLegendItems[3]=true;
    this.clickedLegendItems[4]=true;
    this.clickedLegendItems[5]=true;
    this.clickedLegendItems[6]=true;
    this.clickedLegendItems[7]=true;
    this.clickedLegendItems[8]=true;
  }

  public initializeBarChartData(){
    /*
    label: 
      D = data combinada
      >= D+5 = Pedidos cujo dia de hoje seja 5 ou mais dias maior do que a data combinada
      D+0 = Pedidos cujo dia de hoje seja igual à data combinada
      <= D-4 = Pedidos cujo dia de hoje seja 4 ou mais dias menor do que a data combinada
    */
      this.barChartData = [
        { data: [], label: '>=D+5', stack: stack, backgroundColor: backgroundColorD5, hoverBackgroundColor: backgroundColorD5, borderColor: borderColor },
        { data: [], label: 'D+4', stack: stack, backgroundColor: backgroundColorD4, hoverBackgroundColor: backgroundColorD4, borderColor: borderColor },
        { data: [], label: 'D+3', stack: stack, backgroundColor: backgroundColorD3, hoverBackgroundColor: backgroundColorD3, borderColor: borderColor },
        { data: [], label: 'D+2', stack: stack, backgroundColor: backgroundColorD2, hoverBackgroundColor: backgroundColorD2, borderColor: borderColor },
        { data: [], label: 'D+1', stack: stack, backgroundColor: backgroundColorD1, hoverBackgroundColor: backgroundColorD1, borderColor: borderColor },
        { data: [], label: 'D+0', stack: stack, backgroundColor: backgroundColorD0, hoverBackgroundColor: backgroundColorD0, borderColor: borderColor },
        { data: [], label: 'D-1', stack: stack, backgroundColor: backgroundColorD1n, hoverBackgroundColor: backgroundColorD1n, borderColor: borderColor },
        { data: [], label: 'D-2', stack: stack, backgroundColor: backgroundColorD2n, hoverBackgroundColor: backgroundColorD2n, borderColor: borderColor },
        { data: [], label: '<=D-3', stack: stack, backgroundColor: backgroundColorD3n, hoverBackgroundColor: backgroundColorD3n, borderColor: borderColor },
      ];
      
  }

  public getBarChartData() {

    this.service.listLateOrdersSectors("WIP").then(data => {
      data.forEach(element => {
        this.barChartLabels.push(element.status);
        this.barChartData[0].data.push(element.numberOrdersLateMoreThenFiveDays);
        this.barChartData[1].data.push(element.numberOrdersLateFourDays);
        this.barChartData[2].data.push(element.numberOrdersLateThreeDays);
        this.barChartData[3].data.push(element.numberOrdersLateTwoDays);
        this.barChartData[4].data.push(element.numberOrdersLateOneDay);
        this.barChartData[5].data.push(element.numberOrdersLateToday);
        this.barChartData[6].data.push(element.numberOrdersLateMinusOneDay);
        this.barChartData[7].data.push(element.numberOrdersLateMinusTwoDays);
        this.barChartData[8].data.push(element.numberOrdersLateLessThenMinusThreeDays);
        for(let i=0; i<this.barChartDataQtdItems; i++) {
          this.barChartData[i].hidden=this.clickedLegendItems[i];
        }
      });
      this.chartLevel = 1;
      this.loading = false;
    });
    
  }

  public chartClicked(e){
    if(e.active[0]!=undefined){
      this.barChartLabels = [];
      this.initializeBarChartData();
      const chart = e.active[0]._chart;
      const element = chart.getElementAtEvent(e.event);
      if (element.length && this.chartLevel==1) {
        this.chartLevel=2;
        var idx = element[0]['_index'];
        var cdata = element[0]['_chart'].config.data;
        this.selectedSectorStatus = element[0]['_model'].label;

        // getting clicked legend items from chartLevel 1 to chartLevel 2
        for(let i=0; i<chart.legend.legendItems.length; i++) {
          this.clickedLegendItems[i]=chart.legend.legendItems[i].hidden;
        }

        if(this.selectedSectorStatus=="SEM SETOR") {
          this.service.listLateOrdersStatusWithNoSector("WIP").then(data => {
            data.forEach(element => {
              this.barChartLabels.push(element.status);
              this.barChartData[0].data.push(element.numberOrdersLateMoreThenFiveDays);
              this.barChartData[1].data.push(element.numberOrdersLateFourDays);
              this.barChartData[2].data.push(element.numberOrdersLateThreeDays);
              this.barChartData[3].data.push(element.numberOrdersLateTwoDays);
              this.barChartData[4].data.push(element.numberOrdersLateOneDay);
              this.barChartData[5].data.push(element.numberOrdersLateToday);
              this.barChartData[6].data.push(element.numberOrdersLateMinusOneDay);
              this.barChartData[7].data.push(element.numberOrdersLateMinusTwoDays);
              this.barChartData[8].data.push(element.numberOrdersLateLessThenMinusThreeDays);
              // passing clicked legend items from chartLevel1 to chartLevel2
              for(let i=0; i<chart.legend.legendItems.length; i++) {
                this.barChartData[i].hidden=this.clickedLegendItems[i];
              }
            });
          }); 

        } else {
          this.service.listLateOrdersStatusWithSector("WIP",  this.selectedSectorStatus).then(data => {
            data.forEach(element => {
              this.barChartLabels.push(element.status);
              this.barChartData[0].data.push(element.numberOrdersLateMoreThenFiveDays);
              this.barChartData[1].data.push(element.numberOrdersLateFourDays);
              this.barChartData[2].data.push(element.numberOrdersLateThreeDays);
              this.barChartData[3].data.push(element.numberOrdersLateTwoDays);
              this.barChartData[4].data.push(element.numberOrdersLateOneDay);
              this.barChartData[5].data.push(element.numberOrdersLateToday);
              this.barChartData[6].data.push(element.numberOrdersLateMinusOneDay);
              this.barChartData[7].data.push(element.numberOrdersLateMinusTwoDays);
              this.barChartData[8].data.push(element.numberOrdersLateLessThenMinusThreeDays);
              // passing clicked legend items from chartLevel1 to chartLevel2
              for(let i=0; i<chart.legend.legendItems.length; i++) {
                this.barChartData[i].hidden=this.clickedLegendItems[i];
              }
            });
          });
        }
  
      } else if (element.length && this.chartLevel==2 && this.selectedSectorStatus!="SEM SETOR") {
          this.chartLevel=3;
          // getting clicked legend items from chartLevel 2 to chartLevel 3
          for(let i=0; i<chart.legend.legendItems.length; i++) {
            this.clickedLegendItems[i]=chart.legend.legendItems[i].hidden;
          }
          var idx = element[0]['_index'];
          var cdata = element[0]['_chart'].config.data;
          this.selectedMngtStatus = element[0]['_model'].label;
          this.generateSelectedDaysStr();
          this.service.listLateOrdersBySectorAndStatus("WIP", this.selectedSectorStatus, this.selectedMngtStatus, this.selectedDays).then(data => {
            this.orderList = data['records'];
            this.lengthLista = this.orderList.length > 8 ? false : true;
            this.loadingOrderList = false;
          });
      } else if (element.length && this.chartLevel==2 && this.selectedSectorStatus=="SEM SETOR") {
        this.chartLevel=3;
        // getting clicked legend items from chartLevel 2 to chartLevel 3
        for(let i=0; i<chart.legend.legendItems.length; i++) {
          this.clickedLegendItems[i]=chart.legend.legendItems[i].hidden;
        }
        var idx = element[0]['_index'];
        var cdata = element[0]['_chart'].config.data;
        this.selectedMngtStatus = element[0]['_model'].label;
        this.generateSelectedDaysStr();
        this.service.listLateOrdersByNoSectorAndStatus("WIP", this.selectedSectorStatus, this.selectedMngtStatus, this.selectedDays).then(data => {
          this.orderList = data['records'];
          this.lengthLista = this.orderList.length > 8 ? false : true;
          this.loadingOrderList = false;
        });
    }
    }

  }

  ItensPaginator(ev) {
    this.orderListPaginated = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }

  getTypeEnum(enumCode: string){
    return JobTypeEnum[enumCode];
  }

  onButtonClick() {
    this.getInitialData();
  }

  detalhe(env){
    this.router.navigate([`/main/request-detail/${env}`]);
  }

  generateSelectedDaysStr(){
    this.selectedDays="";
    for(let i=0; i<this.barChartDataQtdItems; i++){
      if(this.clickedLegendItems[i]==false){
        if(this.selectedDays==""){
          this.selectedDays+=this.barChartData[i].label;
        } else {
          this.selectedDays+="AND"+this.barChartData[i].label;
        }
      }
    }
    if(this.selectedDays==""){
      this.selectedDays="all";
    }
  }

  // chart level 1 - sector
  // chart level 2 - mngt status
  // chart level 3 - orders
  
}